<template>

    <div class="button" @mouseover="contactMouseOver = true" @mouseleave="contactMouseOver = false">
<!--
      <img src="@/assets/img/show__button__portfolio.svg" alt="" class="show__first__block">
-->
      <div class="container__for__img">
        <img src="@/assets/img/circle__button.svg" alt="" class="circle__button">
        <img v-if="contactMouseOver"  src="@/assets/img/Ellipse12.svg" alt="" class="circle__button__two">
        <img v-if="!contactMouseOver" src="@/assets/img/circle__button__two.svg" alt="" class="circle__button__two">



      </div>
      <p class="button__router__desktop__text">{{text}}</p>
      <img src="@/assets/img/show__button__arrow__portfolio.svg" alt="" class="arrow__first__block">
    </div>
</template>

<script>
export default {
  name: "Buttons-Router",
  props: {
    text: {
        type: String,
        require: true,
        default: 'Show more'

    }
  },
  data() {
    return {
      contactMouseOver: false,
    }
  },
}
</script>

<style scoped>
.button {
  width: 100%;
  height: 100%;
  /*width: 21.771vw;
  height: 3.542vw;*/
  display: flex;
  align-items: center;
 /* position: absolute;*/
  /*left: 8.333vw;
  bottom: 9.5vw;*/
  justify-content: space-between;

}
.container__for__img{
  width: 3.542vw;
  height: 3.542vw;
  position: relative;
  cursor: pointer;
}
.circle__button__two{
  width: 3.542vw;
  height: 3.542vw;
}
.circle__button{
  width: 0.625vw;
  height: 0.504vw;
  position: absolute;
  top: 1.5vw;
  left: 1.5vw;
}
/*.show__first__block {
  width: 15.052vw;
  height: 3.542vw;
  margin-right: 2.083vw;
}*/
.button__router__desktop__text{
  font-family: 'Benzin-Regular';
  font-size: 1.354vw;
  line-height: 143.9%;
  color: #EFE6E6;
}

.arrow__first__block {
  width: 4.635vw;
  height: 1vw;
}
@media screen and (max-width: 991px) {
    .container__for__img{
        width:9.333vw;
        height: 9.333vw;
        position: relative;
    }
    .circle__button__two{
        width: 9.333vw;
        height: 9.333vw;
    }
    .circle__button{
        width: 2.400vw;
        height: 1.867vw;
        position: absolute;
        top: 3.5vw;
        left: 3.5vw;
    }
    .button__router__desktop__text{
        font-family: 'Benzin-Regular';
        font-size: 4.800vw;
        line-height: 143.9%;
        color: #EFE6E6;
    }

    .arrow__first__block {
        width: 6.667vw;
        height: 2vw;
    }
}

</style>